import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Section from "../components/Layout/Section";
import imgDetail from "../assets/question.png";
import imgDetail2 from "../assets/aboutUs.svg";
import { Box } from "@mui/material";
import wave from "../assets/Vector.svg";
import wave2 from "../assets/Vector2.svg";
import Vision from "../components/AboutUsSections/Vision";
import { rows } from "../data/rows";
export default function AboutUs() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Box
        sx={(theme) => ({
          position: "absolute",
          right: 0,
          height: "100%",
          zIndex: -10,

          [theme.breakpoints.down("md")]: {
            height: "40%",
          },
        })}
      >
        <img src={wave} style={{ height: "70%" }} />
      </Box>
      <Box
        sx={(theme) => ({
          position: "absolute",
          left: 0,
          height: "70%",
          zIndex: -10,
          bottom: 0,
          [theme.breakpoints.down("md")]: {
            height: "40%",
          },
        })}
      >
        <img
          src={wave2}
          style={{
            height: "70%",
          }}
          alt=""
        />
      </Box>

      <Section
        layout="1-column-center"
        content={
          <Box>
            <Typography
              variant="h1"
              sx={(theme) => ({
                fontWeight: 700,
                paddingBottom: "8px",
                color: "var(--color-darknavy)",
                [theme.breakpoints.down("md")]: {
                  paddingLeft: "10px",
                  paddingRight: "10px",
                },
              })}
              component="h1"
            >
              Vem Är Tiger Bilar
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={(theme) => ({
                [theme.breakpoints.down("md")]: {
                  paddingLeft: "20px",
                  paddingRight: "20px",
                },
                lineHeight: 1.6,
                color: "var(--color-darknavy)",
              })}
            >
              Vår verksamhet bygger på en passion för bilar och en förståelse
              för deras betydelse i människors liv. Vi strävar efter att
              underlätta processen att köpa bilar, oavsett skick, genom att
              erbjuda en snabb, enkel och transparent tjänst. Målet är att spara
              tid och minska ansträngningen för varje kund som vill sälja sin
              bil. Vi har erfarenhet av att hantera fordon i alla skick och ser
              till att varje transaktion är enkel, trygg och fördelaktig för
              alla parter
            </Typography>
          </Box>
        }
      />

      <Section
        layout="2-column-content-image"
        content={<Vision />}
        image={
          <img
            src={imgDetail2}
            style={{
              height: "auto",
              maxWidth: "100%",
              width: "600px",
            }}
          />
        }
      />
      <Section
        layout="1-column-center"
        content={
          <Box>
            <Typography
              variant="h2"
              sx={(theme) => ({
                fontWeight: 700,
                lineHeight:2,
                color: "var(--color-darknavy)",
                [theme.breakpoints.down("md")]: {
                  paddingLeft: "10px",
                  paddingRight: "10px",
                },
              })}
              component="h2"
            >
              Vi arbetar för en hållbar framtid
            </Typography>

            <Typography
              variant="body1"
              component="p"
              sx={(theme) => ({
                [theme.breakpoints.down("md")]: {
                  paddingLeft: "20px",
                  paddingRight: "20px",
                },
                lineHeight: 1.6,
                color: "var(--color-darknavy)",
              })}
            >
              På Tiger bilar är hållbarhet en central del av vår verksamhet. Vi
              strävar efter att göra bilinköp så miljövänliga och effektiva som
              möjligt, samtidigt som vi tar vårt ansvar för att minska
              klimatpåverkan med:
            </Typography>
          </Box>
        }
        image={
          <img
            src={imgDetail}
            style={{
              height: "auto",
              maxWidth: "100%",
              width: "600px",
            }}
          />
        }
      />
      <Section layout={"2-column-content"} rows={rows} />
      <Section
        layout={"1-column-center"}
        bgColor='var(--color-lightblue)'
        content={
          <Box pt={4} >
            <Typography
              variant="h2"
              sx={(theme) => ({
                fontWeight: 700,
                color: "var(--color-darknavy)",
                [theme.breakpoints.down("md")]: {
                  paddingLeft: "10px",
                  paddingRight: "10px",
                },
              })}
              component="h2"
            >
              Vill du sälja din bil och samtidigt göra ett hållbart val?
            </Typography>
            <Box p={4}>
              <Button
                variant="contained"
                href="/kontakta-oss"
                sx={{
                  mr: 2,
                  px: 4,
                  py: 1,
                  fontSize: "1.2rem",
                  fontWeight: 700,
                  textTransform: "none",
                  borderColor: "#14192d",
                  color: "white",
                  borderRadius: "50px",
                  backgroundColor: "var(--color-blue)",
                  "&&:hover": {
                    backgroundColor: "var(--color-orange)",
                  },
                }}
              >
                Kontakta oss idag!
              </Button>
            </Box>
          </Box>
        }
      />
    </>
  );
}
