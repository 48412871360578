import React, { useEffect } from "react";
import Speedometer from "../components/Speedometer";
import SlagenLeft from "../components/SlagenLeft";
import SlagenRight from "../components/SlagenRight";
import Button from "@mui/material/Button";
import Benefits from "./Benefits";
import ContactUs from "./ContactUs";
import Reviews from "../components/reviews/Reviews";

function Home() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="home">
      <div className="bannerTitle">
        <span>Få ditt erbjudande på</span>
        <span className="animatedWords">{` 90 sekunder`}</span>
      </div>
      <div className="bannerContent">
        <SlagenLeft />
        <Button
              variant="contained"
              href="#kontakta-oss"
              sx={{
                mr: 2,
                my:3,
                px: 4,
                py: 1,
                fontSize: "1.2rem",
                fontWeight: 700,
                textTransform: "capitalize",
                borderColor: "#14192d",
                color: "white",
                display: { sm: "block", md: "none" },
                borderRadius: "50px",
                backgroundColor: "var(--color-blue)",
                "&&:hover": {
                  backgroundColor: "var(--color-orange)",
                },
              }}
            >
          Sälj Bil Nu!
        </Button>
        <Speedometer />
        <SlagenRight />
      </div>
      <Benefits />
      <ContactUs />
      <Reviews />
    </div>
  );
}

export default Home;
