export const rows = [
  {
    title: "Cirkulär ekonomi i centrum",
    description:
      "Vår verksamhet bygger på principerna för cirkulär ekonomi. Vi säkerställer att de resurser som redan finns används på bästa sätt:<ul><li>Återanvändning: Fordon och reservdelar som är i gott skick får nytt liv hos nya ägare.</li><li>Återvinning: För bilar som inte längre kan användas ser vi till att de demonteras och att material som metall, plast och elektronik återvinns på ett hållbart sätt.</li></ul>",
  },
  {
    title: "Digitala processer för en grönare hantering",
    description:
      "För att minska vår miljöpåverkan är alla våra kontrakt och ägarbyten helt digitala. Det betyder mindre pappersavfall och en smidigare process för dig som kund.",
  },
  {
    title: "Vi stödjer övergången till elbilar",
    description:
      "Vi vet hur viktigt det är att minska transportsektorns utsläpp. Därför uppmuntrar vi användningen av elbilar, som är ett avgörande steg mot en mer hållbar framtid. När vi köper bilar hjälper vi till att främja en övergång till fordon med lägre klimatpåverkan.",
  },
  {
    title: "Vårt löfte till miljön",
    description:
      "Genom att sälja din bil till oss bidrar du till en cirkulär och hållbar bilhandel där resurser återanvänds istället för att slösas. Tillsammans gör vi skillnad för framtiden och för vår planet.",
  },
];
