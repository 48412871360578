import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { sidebarItems } from "../data/SidebarData";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import theme from "./theme";
import logo from "../assets/logo2.png";
import PhoneInTalkTwoToneIcon from "@mui/icons-material/PhoneInTalkTwoTone";
import ForwardToInboxTwoToneIcon from "@mui/icons-material/ForwardToInboxTwoTone";
import { Icon, Button } from "@mui/material";
import { Link as MuiLink } from "@mui/material";
const drawerWidth = 340;

const contactItems = [
  {
    icon: <PhoneInTalkTwoToneIcon />,
    label: "073-992 03 73",
    path: "tel:0739920373",
  },
  {
    icon: <ForwardToInboxTwoToneIcon />,
    label: "info@tigerbilar.se",
    path: "mailto:info@tigerbilar.sehassse",
  },
];

const contactItem = contactItems.map((item, index) => (
  <ListItem sx={{ p: 0 }}>
    <ListItemButton
      component={MuiLink}
      href={item.path}
      sx={{ justifyContent: "center", py: 0 }}
    >
      <Icon sx={{ color: "var(--color-orange)", marginRight: "4px" }}>
        {item.icon}
      </Icon>
      <ListItemText
        sx={{
          color: "white",
          flex: "none",
        }}
        key={index}
        primary={item.label}
      />
    </ListItemButton>
  </ListItem>
));

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Link to="/">
        <Box
          component="img"
          sx={{
            display: "block",
            maxWidth: "190px",
            objectFit: "cover",
            marginLeft: "8px",
          }}
          src={logo}
          alt={"logo"}
        />
      </Link>
      <Divider />
      <List>
        {sidebarItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              onClose={handleDrawerToggle}
              component={Link}
              to={item.path}
            >
              <ListItemIcon
                sx={{ minWidth: "45px", color: "var(--color-orange)" }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                sx={{
                  color: "var(--color-darknavy)",
                  fontWeight: 700,
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          boxShadow: 0,
          flexDirection: { md: "row", xs: "column" },
          justifyContent: "space-around",
          backgroundColor: "var(--color-blue)",
        }}
      >
        <Toolbar
          sx={{
            height: "80px",
            justifyContent: { md: "flex-start", xs: "space-between" },
          }}
        >
          <Link to="/">
            <Box
              component="img"
              sx={{
                display: "block",
                maxWidth: "190px",
                objectFit: "cover",
              }}
              src={logo}
              alt={"logo"}
            />
          </Link>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            {sidebarItems.map((item, index) => (
              <Button
                variant="text"
                component={Link}
                to={item.path}
                key={index}
                sx={{
                  textTransform: "capitalize",
                  color: "white",
                  ml: "15px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  "&:hover": {
                    background: theme.palette.primary.light,
                  },
                }}
              >
                {item.title}
              </Button>
            ))}
          </Box>

          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <MenuIcon sx={{ fontSize: "3.5rem", color: "white" }} />
          </IconButton>
        </Toolbar>
        <List
          disablePadding
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems:'center',
            p: 0,
            backgroundColor: {
              xs: "var(--color-darknavy)",
              md: "var(--color-blue)",
            },
          }}
        >
          <ListItem sx={{display: { xs: "none", md: "block" }}}>
            <Button
              variant="contained"
              width="100%"
              href="#kontakta-oss"
              sx={{
                mr: 2,
                px: 4,
                py: 1,
                fontSize: "1.2rem",
                fontWeight: 700,
                textTransform: "capitalize",
                borderColor: "#14192d",
                color: "white",
                
                borderRadius: "50px",
                backgroundColor: "var(--color-orange)",
                "&&:hover": {
                  backgroundColor: "var(--color-lightblue)",
                  color:"var(--color-orange)"
                },
              }}
            >
              Sälj Nu!
            </Button>
          </ListItem>
          {contactItem}
        </List>
      </AppBar>
      <Box component="nav">
        <Drawer
          anchor="right"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "var(--color-lightblue)",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: { xs: 3, md: 0 } }}>
        <Toolbar />
      </Box>
    </Box>
  );
}

export default DrawerAppBar;
