import React from "react";
import { Box, Typography } from "@mui/material";

const Vision = () => (
  <>
    <Typography
      paddingY={2}
      variant="h2"
      sx={(theme) => ({
        fontWeight: 700,
        textAlign: "left",
        color: "var(--color-darknavy)",
        [theme.breakpoints.down("md")]: {
          paddingLeft: "10px",
          paddingRight: "10px",
          textAlign: "center",
        },
      })}
      component="h2"
    >
      Vår Vision
    </Typography>

    <Typography
      variant="body1"
      component="p"
      sx={(theme) => ({
        textAlign: "left",
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
          paddingLeft: "20px",
          paddingRight: "20px",
        },
        lineHeight: 1.6,
        color: "var(--color-darknavy)",
      })}
    >
      Vi vill vara en förebild inom bilhandel genom att driva en verksamhet som
      är hållbar, modern och ansvarsfull. Vår vision är att skapa en bilmarknad
      där resurser används på ett smart sätt, bilar får nya liv, och där miljön
      alltid är i fokus. Tillsammans skapar vi en bättre framtid, där både bilar
      och miljön tas om hand.
    </Typography>
  </>
);

export default Vision;
