import React from "react";
import { Container, Grid, Box, Typography } from "@mui/material";

const Section = ({ layout, image, bgColor, content, rows = [] }) => {
  return (
    <Box mt={{ md: 8, xs: 4 }} backgroundColor={bgColor}>
      <Container>
        {layout === "1-column-center" && (
          <Box textAlign="center">{content}</Box>
        )}

        {layout === "2-column-image-content" && (
          <Grid container spacing={10} alignItems={"center"}>
            <Grid item xs={12} md={6}>
              <Box>{image}</Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>{content}</Box>
            </Grid>
          </Grid>
        )}

        {layout === "2-column-content-image" && (
          <Grid
            container
            spacing={10}
            sx={(theme) => ({
              alignItems: "center",
              [theme.breakpoints.down("md")]: {
                alignItems: "stretch",
              },
            })}
          >
            <Grid item xs={12} md={6}>
              <Box>{content}</Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>{image}</Box>
            </Grid>
          </Grid>
        )}

        {layout === "2-column-content" && (
          <Grid container spacing={4} alignItems="stretch">
            {rows.map((row, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Box height="100%" display="flex" flexDirection="column">
                  <Typography
                    variant="h3"
                    sx={(theme) => ({
                      textAlign: "left",
                      fontWeight: 700,
                      paddingBottom: "8px",
                      color: "var(--color-darknavy)",
                      [theme.breakpoints.down("md")]: {
                        textAlign: "center",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      },
                    })}
                    component="h3"
                  >
                    {row.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    sx={(theme) => ({
                      textAlign: "left",
                      [theme.breakpoints.down("md")]: {
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        textAlign: "center",
                      },
                      lineHeight: 1.6,
                      color: "var(--color-darknavy)",
                    })}
                    dangerouslySetInnerHTML={{ __html: row.description }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default Section;

// Example usage:
// <Layout
//   type="1-column-center"
//   content={<div>Your centered content here</div>}
// />

// <Layout
//   type="2-column-image-content"
//   image={<img src="/path/to/image.jpg" alt="example" />}
//   content={<div>Your content here</div>}
// />

// <Layout
//   type="2-column-content-image"
//   content={<div>Your content here</div>}
//   image={<img src="/path/to/image.jpg" alt="example" />}
// />
